import { Box } from '@butcherbox/freezer'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import {
  CHECKOUT_PAYMENT_STORAGE_KEY,
  CHECKOUT_SUBSCRIPTION_STORAGE_KEY,
  SESSION_STORAGE_CHECKOUT_ID_KEY,
  SESSION_STORAGE_IS_CART_RECLAIMER_KEY,
} from '~/routes/CheckoutFlow/constants'
import { ICongratulationsState } from '~/routes/CheckoutFlow/PaymentFrame/types'
import YotpoModule from '~/sections/YotpoModule'
import Hero from './Hero/Hero'
import OrderSummary from './OrderSummary/OrderSummary'
import SocialSection from './SocialSection/SocialSection'
import { yotpoWrapper } from './Congratulations.css'
import WelcomeSection from './WelcomeSection/WelcomeSection'
import { CongratulationsFallback } from '~/routes/Congratulations/ErrorFallback'
import { withErrorBoundary } from '~/components/ErrorBoundary/ErrorBoundary'

const Congratulations = ({
  state,
  ...props
}: {
  state: ICongratulationsState
}) => {
  // clean stored cart and subscription
  React.useEffect(() => {
    try {
      window.sessionStorage.removeItem(CHECKOUT_PAYMENT_STORAGE_KEY)
      window.sessionStorage.removeItem(CHECKOUT_SUBSCRIPTION_STORAGE_KEY)
      window.sessionStorage.removeItem(SESSION_STORAGE_CHECKOUT_ID_KEY)
      window.sessionStorage.removeItem(SESSION_STORAGE_IS_CART_RECLAIMER_KEY)
    } catch (err) {
      console.log('Error removing cart from local or session storage', err)
    }
  }, [])

  return (
    <Box data-cy={TEST_ID.CHECKOUT_FLOW_CONGRATULATIONS} {...props}>
      <Hero state={state} />
      <WelcomeSection />
      <OrderSummary state={state} />

      <Box className={yotpoWrapper}>
        <YotpoModule
          customerEmail={state?.customer?.emailAddress}
          customerId={state?.customer?.id}
        />
      </Box>
      <SocialSection />
    </Box>
  )
}

export default withErrorBoundary(Congratulations, {
  fallback: <CongratulationsFallback />,
})
