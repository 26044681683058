import { Box, BoxProps, rem, Text, VisuallyHidden } from '@butcherbox/freezer'
import { CardProductFlag } from 'design/components/CardProduct/CardProduct'
import React from 'react'
import { BoxType } from '~/bb-api/schemata'
import AnimalIcons from '~/components/AnimalIcons/AnimalIcons'
import { getPlanIcon } from '~/utils'
import * as Styles from './OrderSummary.css'

type IOrderSummaryLineItem = {
  altDescription?: React.ReactNode
  description: React.ReactNode
  showFree?: boolean
  imageUrl?: string
  name: string
  price?: React.ReactNode
  boxType?: BoxType
} & BoxProps

function OrderSummaryLineItem({
  altDescription,
  description,
  showFree,
  imageUrl,
  name,
  price,
  boxType,
}: IOrderSummaryLineItem) {
  const iconName = getPlanIcon(boxType)
  return (
    <Box component="li" display="flex" style={{ height: rem(61) }}>
      <Box marginTop={4} style={{ flex: `0 0 ${rem(84)}` }}>
        {imageUrl && !boxType && (
          <>
            {showFree && <CardProductFlag>FREE</CardProductFlag>}
            <Box
              className={Styles.freeBox}
              style={{ backgroundImage: `url("${imageUrl}")` }}
            />
          </>
        )}
        {boxType && (
          <Box aria-hidden="true" className={Styles.boxTypeWrap}>
            {boxType === 'cst' ? (
              <Text color="white" component="p" variant="H4Condensed">
                Your Choice
              </Text>
            ) : (
              <AnimalIcons iconName={iconName} />
            )}
          </Box>
        )}
      </Box>
      <Box className={Styles.boxDetails}>
        <Text
          display="flex"
          justifyContent="spaceBetween"
          variant="H4Condensed"
        >
          <Box as="span">{name}</Box>
          {price && (
            <Box component="span" marginLeft={16}>
              <VisuallyHidden>Price:&nbsp;</VisuallyHidden>
              {showFree ? (
                <Text
                  color="spicedCrimson"
                  component="span"
                  variant="H4Condensed"
                >
                  FREE
                </Text>
              ) : (
                price
              )}
            </Box>
          )}
        </Text>
        <Text variant="Body2Regular">
          <VisuallyHidden component="span">
            {altDescription}&nbsp;
          </VisuallyHidden>
          <Box as="span">{description}</Box>
        </Text>
      </Box>
    </Box>
  )
}
export default OrderSummaryLineItem
