import { Text, TextLink } from '@butcherbox/freezer'

import React from 'react'
export const CongratulationsFallback = () => (
  <Text mx={60} my={60} variant="H3Bold">
    We're unable to load your information. Please contact customer support for
    help at{' '}
    <TextLink href="tel:+1-855-981-8568" underline={true}>
      855-981-8568.
    </TextLink>
  </Text>
)
