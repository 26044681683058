import React from 'react'
import { socialLinks } from '~/data'
import {
  Box,
  BoxProps,
  IconFacebook,
  IconInstagram,
  IconTwitter,
  IconYoutube,
  IconPintrest,
  Color,
} from '@butcherbox/freezer'

interface SocialIconsProps extends BoxProps {
  iconColor: Color
}

const SocialIcons = ({ iconColor, ...props }: SocialIconsProps) => {
  const renderSocialIcon = (service) => {
    switch (service) {
      case 'ig':
        return <IconInstagram customColor={{ base: iconColor }} size="small" />
      case 'pn':
        return <IconPintrest customColor={{ base: iconColor }} size="small" />
      case 'fb':
        return <IconFacebook customColor={{ base: iconColor }} size="small" />
      case 'tw':
        return <IconTwitter customColor={{ base: iconColor }} size="small" />
      case 'yt':
        return <IconYoutube customColor={{ base: iconColor }} size="small" />
      default:
        return null
    }
  }
  return (
    <Box
      aria-label="Social Links"
      component="ul"
      display="flex"
      justifyContent="center"
      {...props}
    >
      {socialLinks.map((item) => (
        <Box as="li" data-what={item['data-what']} key={item.href} p={4}>
          <Box
            component="a"
            href={item.href}
            title={`ButcherBox ${item.title[0].toUpperCase()}${item.title.slice(
              1
            )}`}
          >
            <Box>{renderSocialIcon(item.service)}</Box>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default SocialIcons
