import { Box, Text } from '@butcherbox/freezer'
import React from 'react'

function OrderSummarySection({
  title,
  children,
  ...props
}: {
  title: string
  children: React.ReactNode
}) {
  return (
    <Box marginBottom={12} {...props}>
      <Text component="h3" variant="Body1Regular">
        <strong>{title}</strong>
      </Text>
      {children}
    </Box>
  )
}

export default OrderSummarySection
