import {
  Box,
  Button,
  IconChevronDown,
  IconChevronUp,
  OneColorSVGProps,
  Text,
  VisuallyHidden,
  IconProps,
} from '@butcherbox/freezer'
import dayjs from 'dayjs'
import React from 'react'
import CreditCard from '~/components/CreditCard'
import Panel from '~/components/Panel/index'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'
import { PanelSection } from '~/components/Panel/PanelSection/PanelSection'
import useBoxSizes from '~/hooks/useBoxSizes'
import { ICongratulationsState } from '~/routes/CheckoutFlow/PaymentFrame/types'
import { formatPriceFromCents, getPlanIcon } from '~/utils'
import { normalizeBoxName } from '~/utils/boxVariants'
import duplicateItemsByQuantity from '~/utils/duplicateItemsByQuantity'
import { CongratulationsFallback } from '~/routes/Congratulations/ErrorFallback'
import * as Styles from './OrderSummary.css'
import OrderSummaryLineItem from './OrderSummaryLineItem'
import OrderSummarySection from './OrderSummarySection'
import { withErrorBoundary } from '~/components/ErrorBoundary/ErrorBoundary'

const OrderSummary = ({ state }: { state: ICongratulationsState }) => {
  const [boxItemsExpanded, setBoxItemsExpanded] = React.useState(false)
  const { data: boxSizes = [] } = useBoxSizes(state.subscription.box.type)

  // Return # box items for custom and poundage for curated
  const itemCountOrPoundageForBoxSize = React.useMemo(() => {
    if (boxSizes.length) {
      const currBox = boxSizes.find(
        (el) => el.size === state.subscription.box.size
      )
      return `${currBox.itemCount || currBox.poundage} ${
        state.subscription.box.type === 'cst' ? 'Cuts' : ''
      }`
    } else {
      return 0
    }
  }, [boxSizes, state.subscription.box])

  const chevronIconProps: IconProps<OneColorSVGProps> = {
    customColor: { base: 'spicedCrimson' },
    display: 'inline',
    marginLeft: 4,
    pixelSize: 10,
    size: 'fixed',
    verticalAlign: 'baseline',
  }

  return (
    <div className={Styles.orderSummary}>
      {/* Summary Block need to pass the px props down here because chakra.*/}
      <Panel
        className={Styles.summaryPanel}
        px={{ mobile: 16, tablet: 40 }}
        py={20}
      >
        <Text variant="H2Bold">
          {`Order Number ${state.subscription.id}${state.subscription.month}`}
        </Text>
        <Box
          display="flex"
          flexDirection={{ mobile: 'column', tablet: 'row' }}
          justifyContent="spaceBetween"
          marginTop={16}
        >
          {/* Shipping and delivery info */}
          <div className={Styles.shippingInfo}>
            <OrderSummarySection title="Shipping Info">
              <Text variant="Body1Regular">
                <Box aria-label="Shipping Address Name" data-dd-privacy="mask">
                  {`${state.shipping.firstName} ${state.shipping.lastName}`}
                </Box>
                <Box
                  aria-label="Shipping Address Line One"
                  data-dd-privacy="mask"
                >
                  {state.shipping.addressLineOne}
                </Box>
                <Box
                  aria-label="Shipping Address Line Two"
                  data-dd-privacy="mask"
                >
                  {state.shipping.addressLineTwo}
                </Box>
                <Box
                  aria-label="Shipping Address City, State and Zip Code"
                  data-dd-privacy="mask"
                >
                  {`${state.shipping.city}, ${state.shipping.state} ${state.shipping.postalCode}`}
                </Box>
              </Text>
            </OrderSummarySection>
            <OrderSummarySection title="Contact Info">
              <Text variant="Body1Regular">
                <Box
                  aria-label="Contact Email"
                  data-dd-privacy="mask"
                  style={{ overflowWrap: 'anywhere' }}
                >
                  {state.email}
                </Box>
                <Box aria-label="Contact Phone Number" data-dd-privacy="mask">
                  {state.phoneNumber
                    .replace(/\D/g, '')
                    .replace(/^(1?)(\d{3})(\d{3})(\d{4})$/, '$2-$3-$4')}
                </Box>
              </Text>
            </OrderSummarySection>
            <OrderSummarySection title="Delivery">
              <Text data-chromatic="ignore" variant="Body1Regular">
                {`Starts week of ${
                  dayjs(state.shipping.shipmentWeek).format('dddd, MMM D') || ''
                }`}
              </Text>
            </OrderSummarySection>
            <OrderSummarySection title="Delivery Notes">
              <Text variant="Body1Regular">
                {state.shipping.deliveryInstructions || 'n/a'}
              </Text>
            </OrderSummarySection>
          </div>

          {/* Payment Info and Billing Address */}
          <div className={Styles.paymentInfo}>
            <OrderSummarySection data-private title="Payment Method">
              <CreditCard {...state.customer.billingInformation} />
            </OrderSummarySection>
            <OrderSummarySection title="Billing Address">
              <Text variant="Body1Regular">
                <Box
                  aria-label="Billing address line one"
                  data-dd-privacy="mask"
                >
                  {state.billing.addressLineOne}
                </Box>
                <Box
                  aria-label="Billing address line two"
                  data-dd-privacy="mask"
                >
                  {state.billing.addressLineTwo}
                </Box>
                <Box
                  aria-label="Billing address city, state and zip code"
                  data-dd-privacy="mask"
                >
                  {`${state.billing.city}, ${state.billing.state} ${state.billing.postalCode}`}
                </Box>
              </Text>
            </OrderSummarySection>
          </div>

          {/* Order Summary */}
          <div className={Styles.summary}>
            <PanelSection
              content="Order Summary"
              contentAddon=""
              marginBottom={8}
            />
            <PanelBreak />
            <Box
              component="ul"
              display="flex"
              flexDirection="column"
              marginBottom={16}
            >
              <OrderSummaryLineItem
                boxType={state.subscription.box.type}
                description={
                  state.subscription.box.type === 'cst' ? (
                    <Button
                      aria-label={
                        boxItemsExpanded ? 'Hide box items' : 'Expand box items'
                      }
                      onClick={() => setBoxItemsExpanded(!boxItemsExpanded)}
                      textVariant="Body2Regular"
                      variant="text"
                    >
                      <Box
                        component={'span'}
                        style={{ textTransform: 'capitalize' }}
                      >{`Size: ${state.subscription.box.size} ${itemCountOrPoundageForBoxSize}`}</Box>
                      {boxItemsExpanded ? (
                        <IconChevronUp {...chevronIconProps} />
                      ) : (
                        <IconChevronDown {...chevronIconProps} />
                      )}
                    </Button>
                  ) : (
                    <Box
                      component="span"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {`Size: ${state.subscription.box.size} ${itemCountOrPoundageForBoxSize}`}
                    </Box>
                  )
                }
                imageUrl={getPlanIcon(state.subscription.box.type)}
                name={normalizeBoxName(state.subscription.box) + ' Box'}
                price={formatPriceFromCents(state.subscriptionPrice)}
              />
              {state.subscription.box.type === 'cst' && boxItemsExpanded && (
                <Box aria-label="Custom Box Items" as="ul">
                  {duplicateItemsByQuantity(state.subscription.box.items).map(
                    (boxItem) => {
                      return (
                        <OrderSummaryLineItem
                          altDescription={`Each unit of ${boxItem.description} contains`}
                          as="li"
                          description={boxItem.packSize}
                          imageUrl={boxItem.image}
                          key={boxItem.sku}
                          name={boxItem.description}
                        />
                      )
                    }
                  )}
                  <PanelBreak intensity="light" my={16} />
                </Box>
              )}

              {state.invoiceItems &&
                state.invoiceItems.map((deal) => {
                  return (
                    <OrderSummaryLineItem
                      description={
                        <>
                          {`${deal.quantity} @ ${formatPriceFromCents(
                            deal.price
                          )}`}
                          <VisuallyHidden component="span">
                            &nbsp;per unit
                          </VisuallyHidden>
                        </>
                      }
                      imageUrl={deal.image}
                      key={deal.sku}
                      name={deal.description}
                      price={formatPriceFromCents(deal.price * deal.quantity)}
                    />
                  )
                })}
              {state.subscription.box.offers.map((offer) => {
                return (
                  <OrderSummaryLineItem
                    description={offer.packSize}
                    imageUrl={offer.image}
                    key={offer.sku}
                    name={offer.description}
                    price={formatPriceFromCents(offer.price)}
                    showFree={offer.price === 0}
                  />
                )
              })}
              {state.extras.map((extra) => {
                return (
                  <OrderSummaryLineItem
                    description={extra.packSize}
                    imageUrl={extra.image}
                    key={extra.sku}
                    name={extra.description}
                    price={formatPriceFromCents(extra.price)}
                    showFree={extra.price === 0}
                  />
                )
              })}
            </Box>
            <PanelSection
              content="Subtotal"
              contentAddon={formatPriceFromCents(state.subtotal)}
              marginBottom={12}
            />
            <PanelSection
              content="Shipping"
              contentAddon="FREE"
              marginBottom={12}
            />
            <PanelSection
              content="Tax"
              contentAddon={formatPriceFromCents(state.taxAmount)}
              marginBottom={state.discountAmount ? 12 : 0}
            />
            {state.discountAmount ? (
              <PanelSection
                content="Discount"
                contentAddon={formatPriceFromCents(state.discountAmount)}
              />
            ) : null}
            <PanelBreak marginBottom={20} marginTop={16} />
            <PanelSection
              content="TOTAL"
              contentAddon={formatPriceFromCents(state.total)}
              marginBottom={16}
            />
          </div>
        </Box>
      </Panel>
    </div>
  )
}

export default withErrorBoundary(OrderSummary, {
  fallback: <CongratulationsFallback />,
})
