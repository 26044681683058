import { Lockup, Text, TextLink } from '@butcherbox/freezer'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import React from 'react'
import Panel from '~/components/Panel'
import { Link } from 'gatsby'
import * as Styles from './WelcomeSection.css'

const WelcomeSummary = () => {
  const data = useStaticQuery(graphql`
    query {
      skeweredBeef: file(relativePath: { eq: "skewer.png" }) {
        childImageSharp {
          fluid(maxWidth: 461) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div className={Styles.welcomeSection}>
      {/* Welcome Block need to pass the px props down here because chakra.*/}
      <Panel className={Styles.welcomePanel} px={0} py={0}>
        <div className={Styles.welcomePanelContent}>
          <Lockup>
            <Text variant="H2Bold">Welcome to Butcherbox!</Text>
            <Text variant="Body1Regular">
              We are here to answer your questions. Our new member onboarding
              page has FAQs on your first box, delivery and starter recipes.
            </Text>
            <TextLink
              component={Link}
              display="block"
              to="/faqs/#ShippingAndDelivery"
            >
              Shipping &amp; Delivery FAQs
            </TextLink>
          </Lockup>
        </div>
        <div className={Styles.welcomePanelImageWrap}>
          <Image fluid={data.skeweredBeef.childImageSharp.fluid} />
        </div>
      </Panel>
    </div>
  )
}

export default WelcomeSummary
