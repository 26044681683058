import { RouteComponentProps, WindowLocation } from '@reach/router'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import { navigate } from 'gatsby'
import React from 'react'
import { trackConversionWithPixels } from '~/analytics/events'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'
import { ICongratulationsState } from '~/routes/CheckoutFlow/PaymentFrame/types'
import Congratulations from '~/routes/Congratulations/Congratulations'

import { withErrorBoundary } from '~/components/ErrorBoundary/ErrorBoundary'
import { CongratulationsFallback } from '~/routes/Congratulations/ErrorFallback'

function CongratulationsPage({
  location: { state },
}: RouteComponentProps<{ location: WindowLocation<ICongratulationsState> }>) {
  React.useEffect(() => {
    /**
     * Someone navigating directly to the congratulations page instead of being directed
     * there as a result of the checkout flow should be moved out of the flow.
     */
    if (!state) navigate('/')
    else {
      trackConversionWithPixels({
        discountAmount: state.discountAmount,
        discountCode: state.discountCode,
        email: state.email,
        extras: state.extras,
        shipping: state.shipping,
        subscription: state.subscription,
        taxAmount: state.taxAmount,
        total: state.total,
        phoneNumber: state.phoneNumber,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UnauthenticatedLayout width="100%">
      {state ? <Congratulations state={state} /> : <LoadingSpinner />}
    </UnauthenticatedLayout>
  )
}
const CongratulationsPageFallback = () => (
  <UnauthenticatedLayout width="100%">
    <CongratulationsFallback />
  </UnauthenticatedLayout>
)

export default withErrorBoundary(CongratulationsPage, {
  fallback: <CongratulationsPageFallback />,
})
