import SocialIcons from 'design/components/SocialIcons/SocialIcons'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import React from 'react'
import { Lockup, Box, Text } from '@butcherbox/freezer'
import * as Styles from './SocialSection.css'

const socialImageNames = [
  'steakBowl',
  'steakButter',
  'breakfast',
  'steakPotatoes',
  'fritters',
  'meatCooking',
]

export const SocialSection = () => {
  const data = useStaticQuery(graphql`
    query {
      steakBowl: file(relativePath: { eq: "congrats-steak-bowl.png" }) {
        childImageSharp {
          fluid(maxWidth: 168) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      steakButter: file(relativePath: { eq: "congrats-steak-butter.png" }) {
        childImageSharp {
          fluid(maxWidth: 168) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      breakfast: file(relativePath: { eq: "congrats-breakfast.png" }) {
        childImageSharp {
          fluid(maxWidth: 168) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      steakPotatoes: file(relativePath: { eq: "congrats-steak-potatoes.png" }) {
        childImageSharp {
          fluid(maxWidth: 168) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fritters: file(relativePath: { eq: "congrats-fritters.png" }) {
        childImageSharp {
          fluid(maxWidth: 168) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      meatCooking: file(relativePath: { eq: "congrats-meat-cooking.png" }) {
        childImageSharp {
          fluid(maxWidth: 168) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Box className={Styles.socialSection}>
      <Box className={Styles.contentWrap}>
        <Box className={Styles.textWrap}>
          <Lockup marginBottom={16}>
            <Text variant="H2Bold">#butcherbox</Text>
            <Text variant="Body1Regular">
              Follow ButcherBox on social media for recipes, giveaways and
              promotions for members!
            </Text>
          </Lockup>
          <SocialIcons iconColor="spicedCrimson" m="auto" />
        </Box>
        {socialImageNames.map((imageName) => {
          return (
            <Box className={Styles.socialImage} key={imageName}>
              <Image fluid={data[imageName].childImageSharp.fluid} />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default SocialSection
