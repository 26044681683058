import React from 'react'
import { Box, ButtonLink, IconCheck, Lockup, Text } from '@butcherbox/freezer'
import * as Styles from './SuccessMessage.css'

const SuccessMessage = ({ isWelcomeGuideEnabled }) =>
  isWelcomeGuideEnabled ? (
    <Box className={Styles.SuccessMessageContainer}>
      <Lockup>
        <IconCheck
          customColor={{ base: 'success' }}
          marginBottom={8}
          marginTop={24}
          mx="auto"
          size="small"
          variant="inherit"
        />
        <Text variant="H3Bold">
          You’ve successfully created your account! Log in to view this handy
          guide with helpful tips & resources for the cuts in your first box.
        </Text>
        <ButtonLink
          data-what="first-box-guide-cta"
          href="/account/welcome-guide"
        >
          Log in to view first box guide
        </ButtonLink>
      </Lockup>
    </Box>
  ) : (
    <Box className={Styles.SuccessMessageContainer}>
      <Lockup>
        <IconCheck
          customColor={{ base: 'success' }}
          marginBottom={8}
          marginTop={24}
          mx="auto"
          size="small"
          variant="inherit"
        />
        <Text variant="H3Bold">
          You've successfully created your account! Accounts allow members to
          easily access deals, get order updates and manage their subscription.
        </Text>
        <ButtonLink href="/account/deals">View all member deals</ButtonLink>
      </Lockup>
    </Box>
  )

export default SuccessMessage
