import { Button, rem, Text } from '@butcherbox/freezer'
import Form from 'design/components/Form/Form'
import HeroSplit from 'design/components/HeroSplit/HeroSplit'
import Input from 'design/components/Input/Input'
import Select from 'design/components/Select/Select'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import { Field, Formik } from 'formik'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import React from 'react'
import { useMutation } from 'react-query'
import * as Yup from 'yup'
import { captureNetworkError } from '~/analytics/errors'
import { CREATE_ACCOUNT } from '~/bb-api/endpoints'
import { CreateAccount } from '~/bb-api/schemata'
import { withErrorBoundary } from '~/components/ErrorBoundary/ErrorBoundary'
import useOptimizelyFeature from '~/hooks/useOptimizelyFeature'
import { PASSWORD_R } from '~/routes/AccountProfilePayment/SectionPassword'
import { ICongratulationsState } from '~/routes/CheckoutFlow/PaymentFrame/types'
import { CongratulationsFallback } from '~/routes/Congratulations/ErrorFallback'
import { getFirstErrorFromAPI } from '~/utils/apiErrors'
import axios from '~/utils/axios'
import encryptPassword from '~/utils/encryptPassword'
import SuccessMessage from './SuccessMessage/SuccessMessage'

const ReferenceOptions: {
  value: CreateAccount['reference']
  label: string
}[] = [
  { label: 'Friend or family', value: 'friend_family' },
  { label: 'Blogger or influencer', value: 'blogger_influencer' },
  { label: 'Online article or blog', value: 'online_article_blog' },
  { label: 'Podcast', value: 'podcast' },
  { label: 'Radio', value: 'radio' },
  { label: 'Sirius XM', value: 'sirius_xm' },
  { label: 'Social Media', value: 'social_post' },
  { label: 'Television', value: 'television' },
  { label: 'YouTube', value: 'youtube' },
  { label: 'TikTok', value: 'tiktok' },
  {
    label: 'Billboards, Bus Shelters, Posters',
    value: 'billboards_shelters_posters',
  },
  { label: 'Other', value: 'other' },
]

const FormValidation = Yup.object().shape({
  email: Yup.string().email('Please provide a valid email address'),
  password: Yup.string()
    .matches(
      PASSWORD_R,
      'Passwords must be at least 8 characters in length and contain at least 3 of the following 4 types of characters: lower case letters, upper case letters, numbers, and special characters.'
    )
    .required('Please enter a new password'),
})

const Hero = ({ state }: { state: ICongratulationsState }) => {
  const data = useStaticQuery(graphql`
    query {
      topBg: file(
        relativePath: { eq: "congrats_box_in_test_kitchen_with_contents.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const [welcomeGuide] = useOptimizelyFeature('welcome_guide')
  const showToast = React.useContext(ToastContext)
  let formReferenceSubmitted = ''

  const mutateAccount = ({
    password,
    reference,
  }: {
    password: string
    reference: CreateAccount['reference']
  }) => {
    formReferenceSubmitted = reference
    return axios.post<CreateAccount>(CREATE_ACCOUNT, {
      customerId: state.customer.id,
      token: state.accountCreationToken,
      passwordEncrypted: encryptPassword(password),
      reference: reference || null,
    })
  }

  const [handleCreateAccount, { status: createAccountStatus }] = useMutation(
    mutateAccount,
    {
      onError: (e: any) => {
        const error = getFirstErrorFromAPI(e?.data)
        showToast('error', {
          children: error,
          duration: 10000,
        })
        console.error(e)
        captureNetworkError(e, {
          customerId: state.customer.id,
          token: state.accountCreationToken.replace(/^.{0,12}/gi, '*'),
          passwordEncrypted: '[REDACTED]',
          reference: formReferenceSubmitted || null,
        })
      },
    }
  )

  return (
    <HeroSplit
      backgroundColor="bb.ivory"
      gridTemplateColumns={{ base: '1fr', tablet: '1fr 1fr' }}
      height="auto"
      Image={
        <Image
          fluid={data.topBg.childImageSharp.fluid}
          style={{ height: '100%', objectFit: 'cover' }}
        />
      }
      textAlign="center"
    >
      <Formik
        initialValues={{
          email: state.email,
          password: '',
          reference: '' as CreateAccount['reference'],
        }}
        onSubmit={(values) => handleCreateAccount(values)}
        validationSchema={FormValidation}
      >
        {({ handleSubmit }) => (
          <Form
            alignItems="center"
            display="flex"
            flexDirection="column"
            maxWidth={rem(474)}
            onSubmit={handleSubmit}
            py={rem(50)}
            title="Finalize account creation form"
          >
            <Text color="spicedCrimson" variant="DisplayOne">
              {welcomeGuide && createAccountStatus === 'success' ? (
                <>Time to get cooking!</>
              ) : (
                <>
                  Thank you,{' '}
                  <span data-dd-privacy="mask">{state.billing.firstName}</span>!
                </>
              )}
            </Text>

            {createAccountStatus === 'success' ? (
              <SuccessMessage isWelcomeGuideEnabled={welcomeGuide} />
            ) : (
              <div style={{ maxWidth: rem(327) }}>
                <Text marginBottom={16} variant="H3Bold">
                  Create an account to access member deals, get updates on your
                  order and easily manage your subscription.
                </Text>
                <Field
                  component={CheckoutInput}
                  inputProps={{ disabled: true }}
                  label="Email"
                  mb={rem(24)}
                  name="email"
                />
                <Field
                  component={CheckoutInput}
                  inputProps={{ type: 'password' }}
                  label="Password"
                  mb={rem(24)}
                  name="password"
                />
                <Field
                  component={Select}
                  floatLabel
                  label="Tell us why you joined today?"
                  mb={rem(24)}
                  name="reference"
                  options={ReferenceOptions}
                  textAlign="left"
                />
                <Button
                  loading={createAccountStatus === 'loading'}
                  type="submit"
                  width="fluid"
                >
                  Create Account
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </HeroSplit>
  )
}

// This custom component is necessary to pass props to a component used in a Formik <Field>
function CheckoutInput(props) {
  return <Input floatLabel {...props.field} {...props} />
}

export default withErrorBoundary(Hero, {
  fallback: <CongratulationsFallback />,
})
